
*,
::before,
::after {
    box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
    text-decoration: inherit; /* 1 */
    vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */

html {
    cursor: default; /* 1 */
    line-height: 1.5; /* 2 */
    -moz-tab-size: 4; /* 3 */
    tab-size: 4; /* 3 */
    -webkit-tap-highlight-color: transparent /* 4 */;
    -ms-text-size-adjust: 100%; /* 5 */
    -webkit-text-size-adjust: 100%; /* 5 */
    word-break: break-word; /* 6 */
}


* {
    max-height: inherit;
    max-width: inherit;
}

html, body {
    width: 100%;
    margin: 0;
}

body {
    font-family: var(--font-standard);
    background: rgb(var(--background-color));
    color: rgb(var(--on-background-color));
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: var(--font-heading);
}



.layout,
.layout__header,
.layout__main,
.layout__footer {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
}

.layout__header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
}

.details,
.contact,
.description {
    display: block;
    /*margin: 1em;*/
}


.layout__header {
    background: rgb(var(--surface-color));
    color: rgb(var(--on-surface-color));
    box-shadow: 0 1px 5px rgba(var(--shadow-surface-color), 0.5);
}

@media (--medium-screen) {
    .hero {
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 400px;
        position: relative;
        background-color: rgb(var(--shadow-surface-color));
    }

    .hero__overlay {
        color: rgb(var(--surface-color));
        z-index: 5;
        position: absolute;
        bottom: 10px;
        right: 20px;
    }


    .hero::before {
        /*Photo by Caspar Camille Rubin on Unsplash*/
        background-image: url("img/hero.jpg");
        background-clip: border-box;
        background-attachment: scroll;
        background-size: cover;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0.4;
    }
}


@media (--normal-screen) {


}

.details {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    margin: 10px;
}

.detail {
    background-color: rgb(var(--surface-color));
    color: rgb(var(--on-surface-color));
    box-shadow: 0 1px 5px rgba(var(--shadow-surface-color), 0.5);
    padding: 2rem;
    margin: 10px;
    border-radius: 2px;
}

.detail__title {
    color: rgb(var(--primary-color));
}

.detail__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.detail__technology {
    color: rgb(var(--primary-color));
}

.header__title {
    /*margin-left: 1rem;*/

    margin: 0;
    margin: 1px 1rem;
    font-size: 35px;
    line-height: 1.5;
}


@media (--medium-screen) {
    .detail {
        margin: 0;
    }
}

.contact {
    background-color: rgb(var(--surface-color));
    color: rgb(var(--on-surface-color));
    display: block;
    padding: 5rem;
    text-align: center;
}

.layout__footer {
    font-size: small;
    text-align: center;
    font-weight: lighter;
    /*background-color: rgb(var(--primary-color));*/
    /*color: rgb(var(--on-primary-color));*/
}

.btn__primary,
.btn__primary:visited
{
    line-height: 1.5rem;
    padding: 1rem;
    background-color: rgb(var(--primary-color));
    color: rgb(var(--on-primary-color));
    font-weight: bold;
    font-size: large;
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(var(--shadow-surface-color), 0.5);
    text-decoration: none;
    text-shadow: 0 0 2px rgba(var(--shadow-surface-color), 0.5);
    transition: color,background-color 0.5s ease-in-out;
    margin: 1px 1rem;
    display: inline-block;
}
.btn__primary:hover,
.btn__primary:active {
    background-color: rgb(var(--primary-variant-color));
    color: rgb(var(--on-primary-variant-color));
}

.layout__header .btn__primary {
    line-height: 1rem;
    margin: 1px;
}